import Vue from 'vue'
import { decode } from '@/lib/util'
import { mapActions, mapState } from 'vuex'

Vue.component('recommended-variants', {
  props: [
    'recommendedProduct'
  ],
  data () {
    return {
      isLoading: false
    }
  },
  watch: {
    'app.lastAdd' (value) {
      this.fetchRecommendedVariants()
    },
    'app.cart' (value) {
      this.fetchRecommendedVariants()
    }
  },
  computed: {
    ...mapState([
      'app',
      'recommendedVariants',
      'loading'
    ]),
    cart () {
      return this.app.cart
    },
    cartItemIds () {
      return this.cart.items.map(item => {
        return item.product_id
      })
    },
    recommendedProductData () {
      const listProductData = [...this.recommendedProductAcf, ...this.recommendedProductDefaultData]
      return listProductData[0]
    },
    recommendedProductAcf () {
      const products = this.recommendedVariants.filter(item => !this.cartItemIds.includes(item.id))

      return products.reverse()
    },
    recommendedProductDefaultData () {
      if (!this.recommendedProduct) {
        return []
      }
      const recommendedProduct = JSON.parse(decode(this.recommendedProduct))
      return recommendedProduct.filter(item => !this.cartItemIds.includes(item.id))
    }
  },
  methods: {
    ...mapActions([
      'fetchRecommendedVariants'
    ])
  },
  mounted () {
    this.fetchRecommendedVariants()
    this.isLoading = true
    setTimeout(() => {
      this.isLoading = false
    }, 500)
  }
})
